.removeLogo {
  position: absolute;
  right: 5px;
  top: 5px;
}

.memberCard {
  margin-bottom: 20px;
}

.teamInput {
  margin-bottom: 10px;
}

.certificates {
  margin-bottom: 20px;
}

.logoWrapper {
  margin: -20px 0 10px 0;
  position: relative;
}

.inititalDocFile {
  padding: 0;
}

.initDocRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.alertEditOnlyAdmin {
  margin-bottom: 20px;
}
