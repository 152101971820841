.root {
    margin-top: 60px;
    position: relative;
}

.tabsNavigation {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.tabWrapper {
    margin-top: 10px;
}

.boxed {
    background: white;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}
