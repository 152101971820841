.container {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  padding-top: 0px;
  max-width: 842px;
  margin: auto;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.pdfContent {
  position: relative;
  width: 842px;
  height: 595px;
  background-color: #f4efec;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.content {
  padding: 70px 80px 0;
  position: relative;
  z-index: 1;
}

.text {
  margin-top: 20px;
}

.text,
.dateText {
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  text-align: center;
}

.dateText {
  font-size: 15px;
  line-height: 22px;
}

.logo {
  position: absolute;
  left: 215px;
  bottom: 10px;
  width: 230px;
}

.companyName {
  word-spacing: 5px;
  font-size: 40px;
  line-height: 45px;
  font-weight: 800;
  margin: auto;
  color: #24394b;
  display: grid;
  align-items: center;
  justify-items: center;
  min-height: 120px;
}

.longName {
  font-size: 30px;
  line-height: 35px;
}

.header {
  font-weight: 200;
  color: #3a4f63;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  color: #24394b;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 5px;
}

.description {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #3a4f63;
  margin: auto;
}

.signatureWrapper {
  margin-top: 25px;
  width: 25% !important;
  margin-left: -25px;
}

.pulpQr {
  margin-bottom: 10px;
}

.qrText {
  font-weight: 500;
  font-size: 15px;
  line-height: 13px;
  margin-left: 5px;
  margin-top: 2px;
  text-align: center;
}

.qr {
  width: 120px;
}

.qrImg {
  display: block;
  width: 65px;
  height: 65px;
}

.download {
  display: block;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  cursor: pointer;
  padding: 5px 10px;
}

.downloadBtnWrap {
  margin-bottom: 20px;
}

.downloadImg {
  display: block;
}

.popoverDownload {
  min-width: 120px;
}
