.root,
.siteLayout {
  background: none;
}

.root .content {
  margin: 24px auto;
  padding: 24px;
  padding-left: calc(200px + 24px);
  max-width: calc(var(--container-width) + 200px);
  width: 100%;
  transition: 0.2s all ease-in-out;
}

.root.mobile .content {
  padding-left: 24px;
  max-width: calc(var(--container-width) + 80px);
}
