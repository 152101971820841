.innerRightInfo {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.rightCol {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.info {
  font-size: larger;
}

.fileMessagePlaceholder {
  max-width: 280px;
  margin: 120px auto 20px;
}

.invoiceDisplayWrap {
  text-align: center;
  min-height: 230px;
}

.oneDoc {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  color: inherit;
}

.oneDoc:not(:last-child) {
  border-bottom: 1px solid #f1eaf1;
  margin-bottom: 2px;
  padding-bottom: 2px;
}

.fileIcon {
  color: var(--primary-color);
  font-size: 20px;
}

.fileName {
  justify-self: center;
}

.actionsWrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modalButtons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.invoiceMessages {
  margin-bottom: 20px;
}

.downloadInvoice {
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .rightCol {
    margin-top: 20px;
  }
}
