.switchInvoiceTable {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
}

.invoiceOverDue {
  color: var(--primary-color);
  font-weight: bold;
}

.invoicesPdf {
  display: inline-block;
  margin: 15px 0;
}
