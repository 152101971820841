.root {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;
  width: 100%;
}

.table {
  width: 100%;
}

.colorInherit {
  color: inherit;
}
