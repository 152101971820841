@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');
@import-normalize;

body,
html {
  margin: 0;
  background-color: var(--fade-accent);
  font-family: 'DM Sans', sans-serif;
}

.site-form-item-icon {
  color: var(--primary-color);
}

form.ant-form.ant-form-horizontal .ant-col.ant-form-item-label {
  width: 130px;
  text-align: left;
  margin-top: 7.5px;
}

form.ant-form.ant-form-horizontal .ant-col.ant-form-item-label label {
  white-space: break-spaces;
  line-height: 1.2;
  height: auto;
}

form.ant-form.ant-form-horizontal .ant-col.ant-form-item-control {
  align-self: center;
}

.customTabs .ant-tabs-nav {
  width: 100%;
  position: absolute;
  top: -40px;
  margin: 0;
  left: 0;
}

.customTabs .ant-tabs-tab {
  padding: 4px 15px;
  background: var(--color-white);
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
}

.customTabs .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--primary-color);
  border-color: transparent;
}

.customTabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--color-white);
}

.customTabs.ant-tabs-nav::before {
  display: none;
}

.customTabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 10px;
}

.customTabs .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none;
}

.ant-picker {
  width: 100%;
}

img {
  max-width: 100%;
}

h1 {
  font-size: var(--font-size-h1);
}

.vertical-center {
  vertical-align: middle;
}

.panel {
  border-radius: 5px;
  padding: 20px 25px 20px 25px;
}

.white-bg {
  background: var(--color-white);
}

.full-width {
  width: 100%;
}

.uploaded-image-wrapper {
  position: relative;
  width: 209px;
  height: 220px;
  margin-bottom: 90px;
}

.uploaded-image-wrapper input {
  position: absolute;
  bottom: -40px;
  left: 5px;
}

.uploaded-image-wrapper label {
  position: absolute;
  bottom: -70px;
  left: 5px;
}

.uploaded-image {
  margin: 0px 0 10px 0;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.07));
  border-radius: 10px;
  display: block;
}

.remove-uploaded-image {
  position: absolute;
  left: 10px;
  top: 10px;
}

.inline-block {
  display: inline-block;
}

.photos-wrapper .ant-upload-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 20px;
}

.photos-wrapper .ant-upload-list .ant-upload-list-item-container {
  margin-right: 16px;
}

a {
  color: var(--primary-color);
}

@media screen and (max-width: 1000px) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
  }
  .ant-table table {
    min-width: 1000px;
  }
}

.servicesTabs .ant-tabs-content-holder {
  background: white;
  padding: 20px 20px 10px 20px;
  box-shadow: var(--box-shadow);
  border-radius: 0 10px 10px 10px;
}

.servicesTabs .ant-tabs-nav {
  margin: 0;
  position: relative;
  z-index: 1;
}
