.root {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px;
  gap: 15px;
  flex-wrap: wrap;
}

.pagination {
  text-align: center;
  margin-top: 33px;
}
