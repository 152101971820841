.root {
  z-index: 2;
}

.root .sidebar {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  background-color: var(--color-white);
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
}

.root.mobile {
  position: fixed;
  z-index: 1;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
}

.logo {
  margin: 20px 0 15px;
  text-align: center;
}

.bottom {
  text-align: left;
  padding: 0 4px 20px 12px;
}

.mobile .bottom {
  padding-top: 5px;
}

.inner {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
}

.mobile .inner {
  padding-top: 80px;
  display: block;
}

.button {
  opacity: 0.5;
  transition: 0.2s all ease-in-out;
}

.button:hover {
  opacity: 1;
}
