.root {
  height: 340px;
}

.map {
  height: 360px;
  position: absolute;
  top: 64px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 0;
}

.root:not(.mobile) .map {
  width: calc(100% - 200px);
  left: 200px;
}

.clusterCount {
  width: 24px;
  height: 24px;
  color: #fff;
  background: #8d11c8;
  border-radius: 24px;
  text-align: center;
  border: 2px solid #fff;
}

.pinPopUp {
  text-align: center;
}

.popupImg {
  max-width: 100px;
  max-height: 100px;
  margin: 0 auto;
}

.mapPin {
  cursor: pointer;
  stroke: none;
  filter: drop-shadow(2px 5px 5px rgb(0 0 0 / 50%));
}

.pinCompanyName {
  margin: 0 0 2px;
  color: var(--color-black);
}
