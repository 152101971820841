.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border: 1px solid #f0f0f0;
}

.row:not(:first-child) {
  border-top: none;
}

.cell {
  padding: 10px;
  white-space: pre-wrap;
}

@media screen and (min-width: 600px) {
  .cell:first-child {
    width: 160px;
    padding-right: 20px;
    border-right: 1px solid #f0f0f0;
  }

  .cell:nth-child(2) {
    border-left: 1px solid #f0f0f0;
    width: calc(100% - 159px);
    margin-left: -1px;
  }
}
@media screen and (max-width: 600px) {
  .row {
    flex-direction: column;
    gap: 0;
  }
  .cell:first-child {
    padding-bottom: 0;
    font-weight: bold;
  }
}
