.root {
  min-height: 100vh;
  max-width: 300px;
  margin: 0 auto;
}

.innerWrapper {
  width: 100%;
  text-align: center;
}

.footer {
  width: 100%;
  display: block;
  color: var(--color-gray);
  text-align: center;
  max-width: 270px;
}

.policyLinks {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.policyLinks a {
  color: inherit;
}
