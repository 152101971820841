.documents {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.document {
  box-shadow: var(--box-shadow);
}

.icon {
  display: inline-block;
  vertical-align: middle;
  color: var(--primary-color);
  font-size: 25px;
  width: 25px;
}

.name {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  width: calc(100% - 30px);
}

.innerDoc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 800px) {
  .document {
    width: calc(100% / 2 - 10px);
  }
}

@media screen and (max-width: 800px) {
  .documents {
    flex-direction: column;
  }
}
