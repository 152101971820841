.container {
  margin: 20px auto auto auto;
}

.container,
.pdfContent {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  width: 842px;
  height: 595px;
  background-position: bottom center;
  background-repeat: no-repeat;
  position: relative;
}

.pdfContent {
  background-color: #fff;
  margin: auto;
  overflow: hidden;
}

.content {
  padding: 20px 70px 0;
  position: relative;
  z-index: 1;
}

.text,
.dateText {
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  text-align: left;
}

.dateText {
  font-size: 16px;
  line-height: 20px;
}

.logo {
  margin-bottom: 20px;
  display: block;
  float: right;
  width: 200px;
}

.logoWrap {
  text-align: right;
  width: 100%;
  float: left;
}

.companyName {
  font-weight: 700;
  color: #cf2026;
  padding: 7px 0 11px;
  word-spacing: 5px;
}

.partnerText {
  margin-top: 20px;
}

.partnerText .companyName {
  padding: 20px 0 20px;
}

.redColor {
  color: #cf2026;
}

.titleWrap {
  width: 100%;
  float: left;
}

.title {
  font-style: normal;
  font-size: 40px;
  line-height: 50px;
  color: #cf2026;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  float: left;
  font-weight: 300;
  letter-spacing: 2px;
}

.period {
  padding: 18px 0 0 0;
  width: 240px;
}

.signatureWrapper {
  padding: 24px 0 0 0;
}

.qrText {
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  margin-left: 5px;
  margin-top: 2px;
  max-width: 48px;
  text-align: center;
}

.signature {
  max-width: 130px;
  display: block;
}

.signatureText {
  border-top: 2px solid #000000;
  max-width: 243px;
  text-align: center;
  padding-top: 5px;
  line-height: 1.2;
}

.qr {
  width: 120px;
}

.qrImg {
  display: block;
  width: 59px;
  height: 59px;
}

.footerWrapper {
  background: #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}

.footerText {
  position: absolute;
  bottom: -5px;
  right: 70px;
  font-size: 9px;
  color: white;
}

.footer {
  display: block;
}

.download {
  display: block;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  cursor: pointer;
  padding: 5px 10px;
}

.downloadImg {
  display: block;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.downloadBtnWrap {
  margin-bottom: 20px;
}

.signatureBox {
  margin-top: -30px;
}

.popoverDownload {
  min-width: 120px;
}
