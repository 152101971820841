.root {
  width: 100%;
  position: relative;
  padding-bottom: 90%;
}

.inner img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  text-align: center;
  max-width: 100%;
  max-height: 70%;
}

.profilePic .inner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
}
