.card {
  box-shadow: var(--box-shadow);
}

.space {
  width: 100%;
}

.contactLine a {
  color: inherit;
  word-break: break-all;
}

.contactLine svg {
  color: var(--primary-color);
  margin-right: 5px;
}

.shieldFill {
  fill: var(--primary-color);
  color: var(--primary-color);
}

@media screen and (max-width: 1000px) {
  .teamBox {
    margin-top: 20px;
  }
}
