.logo {
  width: 200px;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  display: inline-block;
  overflow: hidden;
  margin-right: 20px;
  background-color: white;
  padding: 10px;
}

.profilePic {
  padding: 0px;
}

.pulpContainer {
  display: flex;
  align-items: center
}

.about {
  width: calc(100% - 220px);
  display: inline-block;
  vertical-align: top;
}

.name {
  margin: 0 0 10px;
}

.infoLine {
  margin-bottom: 10px;
  font-size: medium;
}

.infoLine span {
  vertical-align: middle;
}

.infoLine svg {
  color: var(--primary-color);
  vertical-align: middle;
  margin-top: -1px;
}

.shieldFill {
  fill: var(--primary-color);
}

@media screen and (min-width: 1000px) {
  .right {
    text-align: right;
  }
}

@media screen and (max-width: 1000px) {
  .right {
    margin-top: 15px;
  }
}

@media screen and (max-width: 550px) {
  .logo {
    width: 100%;
    margin-right: 0;
  }
  .about {
    width: 100%;
    margin-top: 20px;
  }
}
