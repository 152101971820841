.root:not(.noMargin) {
  margin-bottom: 10px;
}

.root svg {
  margin-right: 5px;
}

.root > svg {
  margin-top: -3px;
  vertical-align: middle;
}

.ghost {
  color: var(--color-gray);
}

.bold {
  font-weight: bold;
}

.big {
  font-size: 18px;
}
