.container,
.pdfContent {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  max-width: 840px;
  margin: auto;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.pdfContent {
  position: relative;
  width: 842px;
  height: 595px;
  background-color: #f4efec;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.footerWrapper {
  background-color: #f4efec;
  height: 50px;
}

.content {
  padding: 40px 80px 0;
  position: relative;
  z-index: 1;
  text-align: center;
}

.text,
.dateText {
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
}

.text {
  letter-spacing: 2px;
}

.dateText {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
}

.logo {
  display: block;
  width: 170px;
}

.companyName {
  font-weight: 600;
  margin: 0 0;
  word-spacing: 5px;
  font-size: 30px;
  line-height: 35px;
  text-transform: none;
  letter-spacing: 0;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.companyName.longName {
  font-size: 22px;
  line-height: 1.2;
}

.redColor {
  color: #f9423a;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  color: #f9423a;
  margin: 30px auto 25px;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.period {
  width: 240px;
  margin: 18px auto 0;
}

.signatureCol {
  text-align: center;
}

.signatureWrapper {
  padding: 24px 0 0 0;
}

.qrText {
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  margin-left: 5px;
  margin-top: 2px;
  max-width: 80px;
  text-align: center;
}

.signature {
  max-width: 110px;
  margin: 0 auto;
}

.signatureText {
  border-top: 2px solid #000000;
  max-width: 243px;
  text-align: center;
  padding-top: 5px;
  line-height: 21px;
  font-size: 12px;
}

.signatureTitle {
  font-size: 16px;
}

.qr {
  width: 137px;
}

.qrImg {
  display: block;
  width: 59px;
  height: 59px;
  margin: 0 auto;
}

.footerWrapper {
  background: #fff;
}

.footer {
  display: block;
}

.download {
  display: block;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  cursor: pointer;
  padding: 5px 10px;
}

.downloadBtnWrap {
  margin-bottom: 20px;
}

.downloadImg {
  display: block;
}

.popoverDownload {
  min-width: 120px;
}
