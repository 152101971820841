.root {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;
  margin-bottom: 15px;
}

@media screen and (max-width: 900px) {
  .root {
    flex-wrap: wrap;
  }
}
