.notificationsWrapper {
  width: 250px;
  text-align: center;
}

.scrollInBox {
  max-height: 500px;
  overflow-y: auto;
}

.notification {
  cursor: pointer;
  font-size: medium;
  border-bottom: 1px solid #f1eaf1;
  padding: 10px 0 10px 0;
  text-align: left;
  line-height: 1.3;
}

.notReadNotif {
  font-weight: bold;
}

.notificationTime {
  font-size: smaller;
  color: var(--color-gray);
  margin: 3px 0 0 0;
}

.showMoreBtn {
  margin: 10px auto 0;
}
