.noReviews {
  margin-bottom: 30px;
  color: var(--color-gray);
}

.reviewsRoot {
  margin: 20px 0;
  width: 100%;
}

.review {
  width: 100%;
}

.author,
.date {
  color: var(--color-gray);
}
