.root .header {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 0 30px 0 95px;
  transition: 0.2s padding ease-in-out;
}

.root.mobile .header {
  padding: 0 30px;
}

.root.mobile {
  z-index: 2;
}

.topElement {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 25px;
}

.userIcon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  background-color: var(--fade-accent);
  line-height: 1;
  color: var(--primary-color);
  font-size: 20px;
  border-radius: 100px;
  overflow: hidden;
}

.iconWrapper {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-size: 17px;
  cursor: pointer;
}

.logo {
  position: relative;
  width: 150px;
}

.logo img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 400px) {
  .userElement {
    display: none;
  }
}
