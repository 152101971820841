.currencySelect {
  min-width: 70px;
}

.filesInfo {
  background: none;
  padding: 0;
  border: none;
  color: var(--color-gray);
}

@media screen and (min-width: 1000px) {
  .filesInfo {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .innerFilesCard {
    position: relative;
    min-height: 314px;
    padding-bottom: 85px;
  }
}

.valueInput {
  width: 100%;
}

.docCard {
  margin-top: 5px;
}

.iconAndName,
.innerDocCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.innerDocCard {
  justify-content: space-between;
}

.fileIcon {
  display: inline-block;
  vertical-align: middle;
  color: var(--primary-color);
  font-size: 20px;
  width: 20px;
  margin-bottom: -2px;
}

@media screen and (max-width: 1000px) {
  .invRightSection {
    margin-top: 20px;
  }
}
