.root {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 0 20px 45px;
  width: calc(100% / 4 - 11.25px);
  display: block;
  color: inherit;
  margin-bottom: 15px;
  position: relative;
}

.profilePic {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px 45px;
}

.name {
  margin: 0;
}

.companyContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: auto;
}

.location {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.location svg {
  color: var(--primary-color);
}

@media screen and (max-width: 900px) {
  .root {
    width: calc(100% / 3 - 10px);
  }
}

@media screen and (max-width: 750px) {
  .root {
    width: calc(100% / 2 - 7.5px);
  }
}

@media screen and (max-width: 450px) {
  .root {
    width: 100%;
  }
}
