@media screen and (min-width: 1000px) {
  .cardRoot {
    max-width: 200px;
    margin: 0 0 0 auto;
  }
}

.cardRoot {
  box-shadow: var(--box-shadow);
}

.adminBox {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.adminBox button {
  width: 100%;
}

.status strong {
  text-transform: uppercase;
  margin-right: 10px;
}
