.loginForm {
  text-align: left;
}

.logoWrapper {
  margin-bottom: 30px;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.forgotPassword {
  color: var(--primary-color);
}

.loginBtn {
  min-width: 120px;
}

.logo {
  max-width: 100%;
}
