.loginForm {
  margin-top: 30px;
  text-align: left;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.forgotPassword {
  color: var(--primary-color);
}

.loginBtn {
  min-width: 120px;
}
