.root {
  margin-bottom: 30px;
  justify-content: space-between;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.title {
  margin: 0;
  display: inline-block;
}

.buttonsWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.buttonsWrap button,
.buttonsWrap a {
  box-shadow: none;
}
