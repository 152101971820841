.root {
  width: calc(100% / 4 - 11.25px);
}

@media screen and (max-width: 900px) {
  .root {
    width: calc(100% / 3 - 10px);
  }
}

@media screen and (max-width: 750px) {
  .root {
    width: calc(100% / 2 - 7.5px);
  }
}

@media screen and (max-width: 450px) {
  .root {
    width: 100%;
  }
}
